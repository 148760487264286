<template>
  <StatusBadge :variant="statusBadgeVariant[status]">
    <Icon :icon="statusIcon[status]" />

    <TextEllipsis>
      {{ translateCommercialConditionStatus.fr[status] }}
    </TextEllipsis>

    <!-- The slot enabled to inject a <StatusBadgeRemove /> component -->
    <slot></slot>
  </StatusBadge>
</template>

<script setup lang="ts">
import type { StatusBadgeVariant } from "~/components/ui/status-badge/types";
import type { CommercialConditionStatus } from "@asap/shared";
import { translateCommercialConditionStatus } from "@asap/shared";

defineProps<{ status: CommercialConditionStatus }>();

const statusBadgeVariant: Record<CommercialConditionStatus, StatusBadgeVariant> = {
  not_signed: "warning",
  to_validate: "warning",
  signed: "success",
  refused: "neutral",
  canceled: "neutral",
  sent: "info",
};

const statusIcon: Record<CommercialConditionStatus, string> = {
  not_signed: "lucide:circle-dashed",
  to_validate: "lucide:circle-dashed",
  signed: "lucide:check-circle",
  refused: "lucide:circle-slash",
  canceled: "lucide:circle-slash",
  sent: "lucide:circle-dashed",
};
</script>

<style lang="scss" scoped></style>
